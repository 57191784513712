<template>

	<div class="Customer_Information_form custom-modal-wrap">
		<el-dialog :title="modal_title" :visible.sync="shows" :close-on-click-modal="false"  :close-on-press-escape="false" @closed="onclosed">
			<div class="form_box">
				
				<div class="form_flex">
					<div class="lefts">
						登录账号
					</div>
					<div class="rights">
						<el-input v-model="form.username" placeholder="请输入"></el-input>
					</div>
				</div>
				
				<div class="form_flex">
					<div class="lefts">
						登录密码
					</div>
					<div class="rights">
						<el-input v-model="form.password" placeholder="请输入"></el-input>
					</div>
				</div>
			
				<div class="form_flex">
					<div class="lefts">
						显示名称
					</div>
					<div class="rights">
						<el-input v-model="form.name" placeholder="请输入"></el-input>
					</div>
				</div>
		
				<div class="form_flex">
					<div class="lefts">
						角色
					</div>
					<div class="rights">
						<!-- <input type="text" placeholder="请选择" v-model="form.weixin"> -->
						<el-select v-model="form.role_id" placeholder="请选择" :disabled="disabled_show">
							<el-option v-for="item in juese_list" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts">
						部门
					</div>
					<div class="rights">
						<bumen_cascader ref="bumen_cascader" :multiple="false" :is_show_position="false"
						@confirm="confirm_bumen_cascader_value" />
					</div>
				</div>
				<div class="btn_flex">
					<div class="btns2" @click="handle_ends" v-if="!form.id">确定</div>
					<div class="btns2" @click="handle_ends" v-if="form.id">确定</div>
					<div class="btns1" @click="handle_close">取消</div>
				</div>
			</div>
		</el-dialog>

	</div>
</template>
<script>
	export default {
		name: "Customer_Information_form",
		props: {

		},
		data() {
			return {
				modal_title: '创建账号',
				shows: false,
				form: {
					id: 0,
					name: '',
					role_id: '',
					username: '',
					password: '',
					depart_id:'',
				},
				disabled_show:false,

				//
				juese_list: [{
						title: '类别1',
						value: '1'
					},
					{
						title: '类别2',
						value: '2'
					},
				],
				bumen_select: '',


			};
		},
		computed: {},

		watch: {
			
		},
		created() {
			this.query_juese()
		},
		methods: {
			onclosed(){
				this.form = {
					id: 0,
					name: '',
					role_id: '',
					username: '',
					password: '',
					depart_id:'',
					}
					this.disabled_show=false
			},
			query_juese() {
				this.$api("getAdminRoleList", {
					page: 1,
					limit: 100
				}, "get").then((res) => {
					if (res.code == 200) {
							this.juese_list  = res.data.list;
					} else {
						alertErr(res.msg)
					}
				});
			},

			init(row) {
				this.shows = true
				if (row) {
					this.modal_title = '编辑账号'
					this.query_detail(row)
				
				} else {
					this.modal_title = '创建账号'
				}
			},
			
			query_detail(row) {
				this.$api("getAdmin", {
					id: row.id
				}, "get").then((res) => {
					if (res.code == 200) {
						let data = res.data;
						this.form = {
							id: data.id || 0,
							name: data.name || '',
							role_id: data.role_id || '',
							username: data.username || '',
							password: '',
							depart_id:data.depart_id||''
						}
						if(res.data.role_id==1){
							this.disabled_show=true
						}
						let id_arr=data.departIds
						this.$refs.bumen_cascader.init({
							type: '设置数据',
							value: id_arr,
						})
					} 
				});
			},
			
			
			// 新增打开
			handle_add_open(row) {
				this.title = ''
				this.shows = true
			},
			//修改打开
			handle_modify_open(row) {
				this.title = ''
				let rows = JSON.parse(JSON.stringify(row))
				this.form = rows
				console.log('row', row)

				this.shows = true
			},
			//关闭    
			handle_close() {
				this.shows = false

			},
			confirm_bumen_cascader_value(value){
				this.$log('勾选部门数据', value)
				this.form.depart_id=value[value.length-1]

			},
			//确认添加修改
			handle_ends() {
				if (!this.form.username) {
					alertErr('请输入账号')
					return
				}
				if (!this.form.password&&!this.form.id) {
					alertErr('请输入密码')
					return
				}
				if (!this.form.name) {
					alertErr('请输入显示名称')
					return
				}
				if (!this.form.role_id) {
					alertErr('请选择角色')
					return
				}

				this.loading = true
				this.$api("editAdmin", {
					...this.form
				}, "post").then((res) => {
					alert(res)
					this.loading = false
					if (res.code == 200) {
						this.shows = false
						this.$emit("confirm");
					} 
				});
			},
		},

	};
</script>

<style scoped lang="less">
	.custom-modal-wrap {
		/deep/ .el-dialog .el-dialog__header {
			background: #fff !important;
		}

		.lefts {
			text-align: right;
			margin-right: 15px;
		}
	}

	/deep/ .el-dialog__body {
		padding: 30px 50px 0 50px;
	}

	.btn_flex {
		padding: 25px 0;
		display: flex;
		justify-content: center !important;
	}


	.Customer_Information_form {
		/deep/.el-dialog {
			width: 680px;
		}

		/deep/.el-dialog__header {
			background: #F2F5FA !important;
		}

		.form_box {
			.form_flex {
				display: flex;
				align-items: center;
				margin-bottom: 16px;

				.lefts {
					width: 120px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;

					&::before {
						content: '*';
						display: inline-block;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 16px;
						color: #FC5F03;
					}
				}

				.rights {
					// width: 446px;
					flex: 1;
					height: 40px;
					// background: #F2F5FA;
					border-radius: 4px 4px 4px 4px;

					input {
						padding: 0 12px;
						width: 100%;
						height: 40px;
					}

					/deep/.el-input {
						width: 100%;

							.el-input__inner {
							// background: #F2F5FA;
							// border: 0;
						}
					}

					.el-select {
						width: 100%;
					}
				}
			}

			.btn_flex {
				display: flex;
				align-items: center;
				justify-content: end;

				.btns1 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;
					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #FFFFFF;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #B8B8B8;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #6A6A6A;
				}

				.btns2 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;

					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #2373C8;
					border-radius: 4px 4px 4px 4px;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
				}
			}
		}

	}
</style>